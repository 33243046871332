

































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Pager } from "@/types/Pager";
import { License } from "@/types/License";
import { DatabaseSelect } from "@/types/DatabaseSelect";
import moment from "moment";
import { loadDatabases } from "@/api/databases";
import { activateLicense, loadLicenses } from "@/api/licenses";

@Component({
  components: {}
})
export default class Licenses extends Vue {
  data: License[] = [];
  pagination: Partial<Pager> = {};
  loading = false;
  loadingPagination = false;
  searchEmail = "";
  searchDatabase = 0;
  databases: DatabaseSelect[] = [{ id: 0, name: "All" }];

  debouncedLoadUsers = this.lodash.debounce(this.loadLicenses, 500);

  moment = moment;

  columns = [
    {
      title: "Database",
      key: "database",
      dataIndex: "database",
      scopedSlots: { customRender: "database" }
    },
    {
      title: "User",
      key: "user",
      dataIndex: "user",
      scopedSlots: { customRender: "user" }
    },
    {
      title: "Expires",
      key: "expiredAt",
      dataIndex: "expiredAt",
      scopedSlots: { customRender: "expiredAt" }
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      scopedSlots: { customRender: "status" }
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "20%",
      scopedSlots: { customRender: "action" }
    }
  ];

  mounted() {
    this.loadLicenses(1);
    this.loadDatabases();
  }

  handleTableChange(pagination: Pager) {
    const pager: Partial<Pager> = { ...this.pagination };
    pager.current = pagination.current;
    this.pagination = pager;
    if (pager.current) {
      this.loadLicenses(pager.current);
    }
  }

  async loadDatabases() {
    const { data } = await loadDatabases();
    data.forEach((item: DatabaseSelect) => {
      this.databases.push(item);
    });
  }

  async loadLicenses(page: number, load = true) {
    if (load) {
      this.loading = true;
    }
    const filter = {
      email: this.searchEmail,
      database: this.searchDatabase
    };
    const { data } = await loadLicenses(page, filter);
    this.data = data.data;
    this.pagination.current = data.currentPage;
    this.pagination.pageSize = data.perPage;
    this.pagination.total = data.total;
    this.loading = false;
    this.loadingPagination = true;
  }

  async activateLicense(id: number, status: string) {
    await activateLicense(status, id);
    let current = 1;
    if (typeof this.pagination.current !== "undefined") {
      current = this.pagination.current;
    }
    await this.loadLicenses(current, false);
  }

  @Watch("searchEmail")
  emailSearch() {
    this.debouncedLoadUsers(1);
  }

  @Watch("searchDatabase")
  databaseSearch() {
    this.debouncedLoadUsers(1);
  }

  colorTag = (status: string) => (status === "active" ? "green" : "red");
}
