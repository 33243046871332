import ApiService from "@/api/api.service";
import { AxiosPromise } from "axios";
import { PaginationResponse } from "@/types/PaginationResponse";
import { License } from "@/types/License";

interface LicensesFilters {
  email?: string;
  database?: number;
}

export const loadLicenses = (
  page: number,
  filters: LicensesFilters
): AxiosPromise<PaginationResponse<License[]>> => {
  return ApiService.query("api/admin/licenses", {
    page,
    email: filters.email,
    database: filters.database
  });
};

export const activateLicense = (status: string, id: number) => {
  return ApiService.post(`api/admin/licenses/status/${status}/${id}`, {});
};
